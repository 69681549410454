import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class PricingV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="pricing-one pricing-one__home-three">
            <div className="container">
              <div className="block-title-two text-center">
                <h3>Pricing Plans</h3>
              </div>{/* /.block-title-two */}
              <div className="row">
                <div className="col-lg-6">
                  <div className="pricing-one__single">
                    <div className="pricing-one__inner">
                    {/*<span className="pricing-one__corner-ribbon">40% off!</span>*/}
                    <h3>Monthly Maverick</h3>
                      <div className="pricing-one__top">
                        {/*<p><strike>$500</strike></p>*/}
                        <p>$200</p>
                        <h3>/User/Month</h3>
                      </div>{/* /.pricing-one__top */}
                      <ul className="pricing-one__list list-unstyled">
                      <li><i className="far fa-check" />Turbocharged Carting</li>
                        <li><i className="far fa-check" />Queue Ready</li>
                      
                      </ul>{/* /.pricing-one__list list-unstyled */}

                      <Link to="/contact" className="thm-btn banner-four__btn">Contact us<i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn banner-four__btn */}
                      
                    </div>{/* /.pricing-one__inner */}
                  </div>{/* /.pricing-one__single */}
                </div>{/* /.col-lg-4 */}
                <div className="col-lg-6">
                  <div className="pricing-one__single">
                    <div className="pricing-one__inner">
                      <span className="pricing-one__corner-ribbon">BEST</span>
                      {/* /.pricing-one__corner-ribbon */}
                      <h3>Annual All-Star</h3>
                      <div className="pricing-one__top">
                        {/*<p><strike>$5,600</strike></p>*/}
                        <p>$2,160</p>
                        <h3>/User/Year</h3>
                      </div>{/* /.pricing-one__top */}
                      <ul className="pricing-one__list list-unstyled">
                        <li><i className="far fa-check" />Turbocharged Carting</li>
                        <li><i className="far fa-check" />Queue Ready</li>
         
                        
                        
                      </ul>{/* /.pricing-one__list list-unstyled */}

                      <Link to="/contact" className="thm-btn banner-four__btn">Contact us <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn banner-four__btn */}
                      
    
                    </div>{/* /.pricing-one__inner */}

                    
                  </div>{/* /.pricing-one__single */}
                </div>{/* /.col-lg-4 */}

                
                
              </div>{/* /.row */}
            </div>{/* /.container */}
          </section>

        }
}

export default PricingV2