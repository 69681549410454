import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ServiceV3 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return	<section className="service-four  go-top">
		  <div className="container">
		    {/*<div className="row">
		      <div className="col-lg-3 col-md-6">
		        <div className="service-four__single">
		          <i className="fas fa-bolt" />
		          <h3>Fast Carting</h3>
		          <p>Experience unprecedented speed. Say goodbye to the hassle of selecting dots one by one</p>
		        </div>
		      </div>
		      <div className="col-lg-3 col-md-6">
		        <div className="service-four__single">
		          <i className="fas fa-eye-slash" />
		          <h3>Enhanced Privacy</h3>
		          <p>inCart ensures that you're not categorized as a bot, allowing you to maintain your anonymity while enjoying a fast carting experience.</p>
		        </div>
		      </div>
		      <div className="col-lg-3 col-md-6">
		        <div className="service-four__single">
		          <i className="fas fa-piggy-bank" />
		          <h3>Cost-Effective Solution</h3>
		          <p>Faster carting means reduced manpower, translating to tangible cost savings. Achieve more with less, and boost your bottom line.</p>
		        </div>
		      </div>
		      <div className="col-lg-3 col-md-6">
		        <div className="service-four__single">
		          <i className="fas fa-browser" />
		          <h3>Universal Compatibility</h3>
		          <p>For all Platforms, whether you prefer using a standard or a <a href='https://www.google.com/search?q=multi-session+browser+for+ticketing' target='_blank'>Multi-session</a> Chrome-based browser, inCart offers a version to suit your needs.</p>
		        </div>
		      </div>
	</div>*/}

		

			<h3>Better Online tickets Sales Act</h3>
			<p>inCart does not operate as a ticket bot, as defined by the "Better Online Tickets Sales Act." Unlike ticket bots, inCart does not automate the process of searching for and buying tickets automatically. 
				Instead, it assists users in adding seats to their cart but requires human action to select the first seat and proceed with the financial transaction. 
				inCart does not engage in automated ticket purchases, nor does it bypass any authentication codes or conduct transactions at lightning speed. 
				<br></br>We prioritize a user-driven and manual approach to the ticket purchasing process. </p>
		 
		  </div>{/* /.container */}
		</section>



        }
}

export default ServiceV3