import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';



class CtaV4 extends Component {

	componentDidMount() {
        if (this.videoRef && this.videoRef.current) {
            this.videoRef.current.play();
        }
    }


    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <section className="cta-seven  go-top">

<video  
                    ref={videoRef => this.videoRef = videoRef} 
                    className="cta-seven__moc" 
					width='1139px'
					height='800px' 
                    controls 
                    loop
					autoPlay 
                    muted>
                    <source src={publicUrl+"assets/videos/video2.mp4" } type="video/mp4" />
                </video>

			 

	



			  <div className="container">
			    <div className="row justify-content-end">
			      <div className="col-lg-5">
			        <div className="cta-seven__content">
			          
			          <h3>Intelligent Carting Dynamics</h3>
			          <p>Dive into a revolutionary carting experience with our plugin's advanced logic. 
						It doesn't just focus on your immediate section of interest but also intuitively prepares for surrounding sections. 
						Benefit from a holistic approach that captures from all directions, ensuring a seamless and comprehensive carting experience.</p>
			          
			          {/* /.thm-btn cta-six__btn */}
			        </div>{/* /.cta-seven__content */}
			      </div>{/* /.col-lg-5 */}
			    </div>{/* /.row */}
			  </div>{/* /.container */}
			</section>

        }
	}

export default CtaV4