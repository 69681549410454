import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class FooterV4 extends Component {

  componentDidMount() {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + '/'
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/theme.js";

    document.body.appendChild(minscript);

    $('.go-top').find('a').on('click', function () {
      $(window).scrollTop(0);
    });


  }

  render() {

    let publicUrl = process.env.PUBLIC_URL + '/'
    let imgattr = "Footer logo"

    return (
      <footer className="site-footer-four  go-top">
        <div className="site-footer-four__bubble-1" />{/* /.site-footer-four__bubble-1 */}
        <div className="site-footer-four__bubble-2" />{/* /.site-footer-four__bubble-2 */}
        <div className="site-footer-four__bubble-3" />{/* /.site-footer-four__bubble-3 */}
        <div className="site-footer-four__bubble-4" />{/* /.site-footer-four__bubble-4 */}
        <div className="site-footer-four__mc-wrap">
        </div>{/* /.site-footer-four__mc-wrap */}
        
        <div className="site-footer-four__upper">
          <div className="container text-center">
            <div className="site-footer-four__upper-sep" />{/* /.site-footer-four__upper-sep */}
            <div className="row">
              <div className="col-lg-12">
                <div className="footer-widget footer-widget__about">
                  <a href="/"><img src={publicUrl + "assets/images/mtlogo-1.png"} alt={imgattr} /></a>
                  <p>inCart | Carting Assistant</p>
                  {/*<div className="footer-widget__social">
											<a href="https://www.discord.com/"><i className="fab fa-discord" /></a>
								</div> /.footer-widget__social */}

                <div className="site-footer-four__bottom">
          <div className="container text-center">
            <div className="site-footer-four__bottom-sep" />{/* /.site-footer-four__upper-sep */}
            <p>inCart is made by <a href="https://nine42.com">Nine 42</a></p>
          </div>{/* /.container */}
        </div>{/* /.site-footer-four__bottom */}
                </div>{/* /.footer-widget footer-widget__about */}
              </div>{/* /.col-lg-4 */}
              
            </div>{/* /.row */}
          </div>{/* /.container */}
        </div>{/* /.site-footer-four__upper */}
       
      
      </footer>

    )
  }
}


export default FooterV4