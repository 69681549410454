import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class TestimonialV4 extends Component {

	     componentDidMount() {

        const $ = window.$;
         if ($('.testimonials-three__carousel').length) {
            //Init the carousel
            initSlider();

            function initSlider() {
                $(".testimonials-three__carousel").owlCarousel({
                    items: 1,
                    loop: true,
                    dots: true,
                    nav: false,
                    autoplay: true,
                    //onInitialized: startProgressBar,
                    //onTranslate: resetProgressBar,
                    //onTranslated: startProgressBar
                });
            }

            
	        }

	    }

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="testimonials-three">
			  <div className="container">
			    <div className="testimonials-three__block text-center">
			      <h3>Feedback from Our Customers</h3>
			      <p>Every feature of the inCart plugin is crafted with users in mind. But rather than hearing it from us, 
			        <br /> let's shine a light on the authentic experiences and stories of those who've embraced its capabilities.</p>
			    </div>{/* /.testimonials-three__block */}
			    <div className="testimonials-three__carousel owl-carousel owl-theme">
			      <div className="item">
			        <div className="testimonials-three__single">
			          <div className="row">
			            <div className="col-lg-5">
			              <div className="testimonials-three__image">
			                <img src={publicUrl+"assets/images/testimonials/lorenaw.png"} alt={ imagealt } />
			              </div>{/* /.testimonials-three__image */}
			            </div>{/* /.col-lg-5 */}
			            <div className="col-lg-7 d-flex">
			              <div className="my-auto">
			                <div className="testimonials-three__content">
			                  <p>We've tried a bunch of other solutions, but inCart truly stands out. 
								The auto 'next' button feature? Absolute game-changer. It's like it reads my mind before I even know what I want. Highly recommend!</p>
			                  <h3>Lorena W.</h3>
			                  <span>Team Manager</span>
			                </div>{/* /.testimonials-three__content */}
			              </div>{/* /.my-auto */}
			            </div>{/* /.col-lg-7 */}
			          </div>{/* /.row */}
			        </div>{/* /.testimonials-three__single */}
			      </div>{/* /.item */}
			      <div className="item">
			        <div className="testimonials-three__single">
			          <div className="row">
			            <div className="col-lg-5">
			              <div className="testimonials-three__image">
			                <img src={publicUrl+"assets/images/testimonials/kevint.png"} alt={ imagealt } />
			              </div>{/* /.testimonials-three__image */}
			            </div>{/* /.col-lg-5 */}
			            <div className="col-lg-7 d-flex">
			              <div className="my-auto">
			                <div className="testimonials-three__content">
			                  <p>Carting used to be such a chore. Now, with inCart, I'm done in one eight of the time. 
								It's efficient, intuitive, and just brilliant. Can't go back to anything else after this.</p>
			                  <h3>Kevin T.</h3>
			                  <span>User</span>
			                </div>{/* /.testimonials-three__content */}
			              </div>{/* /.my-auto */}
			            </div>{/* /.col-lg-7 */}
			          </div>{/* /.row */}
			        </div>{/* /.testimonials-three__single */}
			      </div>{/* /.item */}
			      <div className="item">
			        <div className="testimonials-three__single">
			          <div className="row">
			            <div className="col-lg-5">
			              <div className="testimonials-three__image">
			                <img src={publicUrl+"assets/images/testimonials/paulk.png"} alt={ imagealt } />
			              </div>{/* /.testimonials-three__image */}
			            </div>{/* /.col-lg-5 */}
			            <div className="col-lg-7 d-flex">
			              <div className="my-auto">
			                <div className="testimonials-three__content">
			                  <p>Honestly, I was skeptical at first. But once I gave inCart a shot, it was a revelation. It's more than just a plugin; it's a shopping assistant that's got your back.</p>
			                  <h3>Paul K.</h3>
			                  <span>CEO</span>
			                </div>{/* /.testimonials-three__content */}
			              </div>{/* /.my-auto */}
			            </div>{/* /.col-lg-7 */}
			          </div>{/* /.row */}
			        </div>{/* /.testimonials-three__single */}
			      </div>{/* /.item */}
			    </div>{/* /.testimonials-three__carousel owl-carousel thm__owl-carousel owl-theme */}
			    <div className="testimonials-three__slide-progress">
			      <span />
			    </div>{/* /.slide-progress */}
			  </div>{/* /.container */}
			</section>
        }
}

export default TestimonialV4