import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ServiceV4 extends Component {

    render() {


    return	<section className="service-five  go-top">
			  <div className="container">
			    <div className="row">
			      
				  <div className="col-lg-4">
			        <div className="service-five__single">
			          <div className="service-five__single-inner">
			            <div className="service-five__icon">
			              <i className="far fa-chart-line" />
			            </div>{/* /.service-five__icon */}
			            <h3>It is fast</h3>
			            <p>Maximize your carting efficiency with inCart's faster carting capabilities. 
							Let it handle it all in a fraction of the time, ensuring a smooth and uninterrupted flow.</p>
			          </div>{/* /.service-five__single-inner */}
			        </div>{/* /.service-five__single */}
			      </div>{/* /.col-lg-4 */}
			      
				  <div className="col-lg-4">
			        <div className="service-five__single">
			          <div className="service-five__single-inner">
			            <div className="service-five__icon">
			              <i className="far fa-rss" />
			            </div>{/* /.service-five__icon */}
			            <h3>It is smart </h3>
			            <p>Go beyond basic carting with our intelligent carting dynamics. inCart not only targets your primary area of interest but also anticipates needs from surrounding sections. 
							</p>
			          </div>{/* /.service-five__single-inner */}
			        </div>{/* /.service-five__single */}
			      </div>{/* /.col-lg-4 */}

			
				  <div className="col-lg-4">
					<div className="service-five__single">
			          <div className="service-five__single-inner">
			            <div className="service-five__icon">
			              <i className="far fa-unlock-alt" />
			            </div>{/* /.service-five__icon */}
			            <h3>It just works</h3>
			            <p>inCart works with both standard and <a href='https://www.google.com/search?q=multi-session+browser+for+ticketing' target='_blank'>Multi-session</a> Chrome-based browsers, ensuring consistent performance across various platforms.</p>
			          </div>{/* /.service-five__single-inner */}
			        </div>{/* /.service-five__single */}
			      </div>{/* /.col-lg-4 */}
			    </div>{/* /.row */}
			  </div>{/* /.container */}
			</section>


        }
}

export default ServiceV4