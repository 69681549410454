import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class BannerV4 extends Component {
   
	componentDidMount() {
        if (this.videoRef && this.videoRef.current) {
            this.videoRef.current.play();
        }
    }


	render() {
        let publicUrl = process.env.PUBLIC_URL + '/';
        let imagealt = 'image';  // Note: this variable is defined but not used.

        return (
            <section className="banner-four go-top">

			<video  
                    ref={videoRef => this.videoRef = videoRef} 
                    className="banner-four__moc" 
                    width="1108px" 
                    height="701px" 
                    controls 
                    loop
					autoPlay 
                    muted>
                    <source src={publicUrl + "assets/videos/banner.mp4"} type="video/mp4" />
                </video>



                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="banner-four__content">
                                <h4>Any chrome-based browser<span>Ready</span></h4>
                                {/*<h4>Jancy, Insomniac and Chrome based<span>New</span></h4>*/}
                                <h3>inCart<br />
                                    <span>Plugin</span></h3>
                                <p>The Carting Assistant</p>
                                <Link to="/contact" className="thm-btn banner-four__btn">
                                    Get it now! <i className="fa fa-angle-double-right" />
        </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default BannerV4;
