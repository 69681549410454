import React, { Component } from 'react';

class Contact extends Component {

	constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            subject: '',
            how: '',
            message: '',
            isSubmitted: false,
            errorMessage: '', // New state variable for error message
            userIp: ''
        };
    }

    componentDidMount() {
        this.fetchUserIP();
    };

    fetchUserIP = () => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => this.setState({ userIp: data.ip }))
            .catch(error => console.error("Failed to fetch user's IP address:", error));
    };


    validateForm = () => {
      const { name, email, subject, message, how } = this.state;

      if (!name || !email || !subject || !message || !how) {
          this.setState({ errorMessage: 'Please fill in all fields.' });
          return false;
      }

      // Email validation regex pattern
      const emailRegex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!emailRegex.test(email.toLowerCase())) {
          this.setState({ errorMessage: 'Please enter a valid email address.' });
          return false;
      }

      this.setState({ errorMessage: '' });
      return true;
  }


    handleSubmit = async (e) => {
      e.preventDefault();
      //const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      //await sleep(300);

      if (!this.validateForm()) {
        return; // Stop the form submission if validation fails
    }

      //const slackWebhookURL = "https://hooks.slack.com/services/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";  // TEST
        const slackWebhookURL = "https://hooks.slack.com/services/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";  // LIVE
   
      var websiteform = {
        "channel": "C1H9RESGL",
        "blocks": [
            {
                "type": "section",
                "text": {
                    "type": "mrkdwn",
                    "text": "*inCart Contact Form:*"+
                        "\n*Subject:*" +
                        "\n"+this.state.subject+
                        "\n*Source:*" +
                        "\n"+this.state.how+
                        "\n*Message:*"+
                        "\n"+this.state.message+
                        "\n*IP:*"+
                        "\n"+this.state.userIp
                },
                "accessory": {
                    "type": "image",
                    "image_url": "https://api.slack.com/img/blocks/bkb_template_images/approvalsNewDevice.png",
                    "alt_text": "computer thumbnail"
                }
            },
            {
                "type": "section",
                "text": {
                    "type": "mrkdwn",
                    "text": "Client Info:"+
                        "\n"+ this.state.name + " | " + this.state.email
                }
            }
        ]
    }


    const data = websiteform

    //console.log(data)
    try {
      const response = await fetch(slackWebhookURL, {
          method: 'POST',
          headers: {
              // If you need to set headers, do it here
              // 'Content-Type': 'application/json' is a common header, but since you're deleting it in Axios, it's omitted here
          },
          body: JSON.stringify(data)  // Your data goes here
      });
  
     if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }
     

      this.setState({ name: '', email: '', subject: '', message: '', how: '', isSubmitted: true, errorMessage: '' });
      console.log("Form submitted and state updated."); // Debug log
  } catch (error) {
      console.error("Error submitting form", error);
  }




  }

    render() {

    return <div>
		  <section className="contact-two">
			

	


			<div className="container">
		      <div className="row">

             
		       
		        <div className="col-lg-9">
					<form action="#" className="contact-two__form" onSubmit={this.handleSubmit}>
                                    <div className="contact-two__block">
									<h3>Contact Form </h3>
		              <p>We respond exclusively to inquiries of a serious nature.</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input type="text" placeholder="Full Name" value={this.state.name} onChange={e => this.setState({ name: e.target.value })} />
                                        </div>
                                        <div className="col-md-6">
                                            <input type="text" placeholder="Email Address" value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
                                        </div>
                                        <div className="col-md-12">
                                            <input type="text" placeholder="Subject" value={this.state.subject} onChange={e => this.setState({ subject: e.target.value })} />
                                        </div>
                                        <div className="col-md-12">
                                            <input type="text" placeholder="Who referred you to us?" value={this.state.how} onChange={e => this.setState({ how: e.target.value })} />
                                        </div>
                                        <div className="col-md-12">
                                            <textarea name="message" placeholder="Message" value={this.state.message} onChange={e => this.setState({ message: e.target.value })} />
                                            <button type="submit" className="thm-btn contact-two__btn">Send Message <i className="fa fa-angle-double-right" /></button>
                                        </div>
                                    </div>
                                </form>

                                {this.state.isSubmitted && <div>Message Sent Successfully!</div>} {/* Display success message */}
                                {this.state.errorMessage && <div className="error-message">{this.state.errorMessage}</div>} {/* Display error message */}
		        </div>{/* /.col-lg-7 */}

                <div className="col-lg-3">
		          <div className="contact-two__content">
		            <div className="contact-two__block">
		            <h3>DM</h3>
		            </div>{/* /.contact-two__block */}
		            <ul className="list-unstyled contact-two__info-list">
		              <li>
		                <div className="contact-two__info-list-title">
                        <i className="fab fa-discord" />
		                  Discord :
		                </div>{/* /.contact-two__info-list-title */}
		                <p>moderntix_62272</p>
		              </li>
		            
		             
		            </ul>{/* /.contact-two__info-list */}
		          </div>{/* /.contact-two__content */}
		        </div>{/* /.col-lg-5 */}

              


		      </div>{/* /.row */}
		    </div>{/* /.container */}
			
		  </section>{/* /.contact-two */}

		 
		
		</div>

        }
}

export default Contact