import React, { Component } from 'react';


class CtaV5 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <section className="cta-eight text-center  go-top">
			  <div className="cta-eight__content">
			    <h3>Advanced Functionality</h3>
			    <p>Dive into unparalleled convenience with inCart. Beyond its smart carting prowess:<br />
				- Relish the automatic 'next' button feature, pushing you forward swiftly.<br />
				- Managing multiple tabs? Configure each tab's settings independently when in queue, giving you precise control over your carting choices.</p>
			  </div>{/* /.cta-eight__content */}
			  {/*<img src={publicUrl+"assets/images/resources/home-4-moc-3.png"} alt={ imagealt } className="img-fluid" />*/}
			  <video  
                    ref={videoRef => this.videoRef = videoRef} 
                    className="img-fluid" 
                    width="1600px" 
                    height="985px" 
                    controls 
                    loop
					autoPlay 
                    muted>
                    <source src={publicUrl + "assets/videos/next_b.mp4"} type="video/mp4" />
                </video>

			</section>

        }
	}

export default CtaV5